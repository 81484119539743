import theme from 'theme';

const styles = {
  containerNbrSessions: {
    paddingBottom: '100px',
    display: 'flex',
    alignItems: 'center',
  },
  nbrSesstionText: {
    color: '#1A1A1A',
    textTransform: 'none',
    fontSize: '14px',
    fontWeight: 400,
  },
  addButton: {
    width: '250px',
    height: '40px',
    borderRadius: '64px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'primary.dark',
    color: 'white',
    fontWeight: 500,
    fontSize: '15px',
    boxShadow: '0px 3px 5px -1px #00000033',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'primary.dark',
    },
  },
  containerButton: {
    paddingBottom: '9px',
  },
  containerSessionTemplateDialog: {
    backgroundColor: 'white',
    borderRadius: '16px',
    minWidth: '1100px',
    height: '1107px',
  },
  containerButtons: {
    border: '5px solid red',
  },
  btnBack: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '19px 77px',
    borderTop: '1px solid #dfe0e0',
  },
  btnSave: {
    backgroundColor: 'primary.dark',
  },
  containerInputsSessionDialog: {
    padding: '20px 0px',
  },
  titleContent: {
    color: theme.palette.primary.dark,
    fontSize: '40px',
    fontWeight: 800,
    lineHeight: '46.76px',
  },
  themeContent: {
    fontSize: '16px',
    fontWeight: 600,
  },
  closeButton: {
    position: 'absolute',
    right: 8,
    top: 8,
    color: (theme) => theme.palette.grey[500],
  },
  title: {
    color: '#000000DE',
    fontSize: '24px',
    fontWeight: 700,
    lineHeight: '30px',
  },
  containerTitle: {
    height: '78px',
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  content: {
    overflowX: 'hidden',
    '&::-webkit-scrollbar': {
      '-webkit-appearance': 'none',
      width: '4px',
    },
    '&::-webkit-scrollbar-thumb': {
      borderRadius: '25px',
      backgroundColor: 'rgba(0, 0, 0, 0.5)',
      '-webkit-box-shadow': '0 0 1px rgba(255, 255, 255, 0.5)',
    },
  },
};
export default styles;
