/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';

// Style
import styles from './styles';
import { parseJsonTranslate } from 'utils/parse';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellSmallTextWithTooltip({ text }) {
  /* ******************************** RENDERING ******************************* */
  return (
    <Tooltip title={text}>
      <Typography sx={styles.cellText}>{parseJsonTranslate(text)}</Typography>
    </Tooltip>
  );
}

export default CellSmallTextWithTooltip;
