/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React from 'react';

// UI Lib dependencies
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import { getEmailTemplate } from 'utils/templateEmailUtils';
import Tooltip from '@mui/material/Tooltip';
import { useTranslation } from 'react-i18next';
import { truncateString } from 'common/utils';
import { useEmailStructure } from 'hooks/queries';
import { parseJsonTranslate } from 'utils/parse';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './PreviewEmail.styles';
import { EMAIL_FROM, EMAIL_PREVIEW_TITLE, EMAIL_SUBJECT, EMAIL_TO, VALIDATE } from 'litterals';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const PreviewEmail = ({
  isOpen,
  setOpen = () => {},
  title = '_title',
  clientEmail = '_to',
  content = '',
  fromName = '',
  subject = '',
  customHeaderPicture = undefined,
  templateName = '',
  targetRoles,
}) => {
  const { t } = useTranslation();
  const { data: TemplateEmailList, isLoading } = useEmailStructure();

  if (isLoading) return <p>Loading...</p>;

  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
  };

  const footer = parseJsonTranslate(TemplateEmailList.footer);

  const subjectTranslated = parseJsonTranslate(subject);

  const contentTranslated = parseJsonTranslate(content);

  const recipient = clientEmail && clientEmail !== '_to' ? clientEmail : targetRoles;

  const description = templateName ? t(`email_template.template_desc.${templateName}`) : '';

  return (
    <Dialog PaperProps={{ sx: styles.container }} open={isOpen} onClose={handleClose}>
      <DialogTitle sx={styles.containerTitle}>
        {title && <Typography style={styles.title}>{t(EMAIL_PREVIEW_TITLE) + ' ' + title}</Typography>}
        {false && (
          <Box sx={styles.row}>
            <Typography style={styles.subTitle}>{t(EMAIL_FROM) + ' : '}</Typography>
            <Box sx={{ width: '10px' }} />
            <Tooltip sx={{ cursor: fromName.length > 30 && 'pointer' }} title={fromName.length > 60 ? fromName : null}>
              <Typography style={styles.value}>{truncateString(`${fromName}`, 60)}</Typography>
            </Tooltip>
          </Box>
        )}
        {recipient && (
          <Box sx={styles.row}>
            <Typography style={styles.subTitle}>{t(EMAIL_TO) + ' : '}</Typography>
            <Box sx={{ width: '10px' }} />
            <Tooltip
              sx={{ cursor: recipient && recipient.length > 60 && 'pointer' }}
              title={recipient && recipient.length > 60 ? recipient : null}>
              <Typography style={styles.value}>{truncateString(`${recipient}`, 60)}</Typography>
            </Tooltip>
          </Box>
        )}
        {description && (
          <Box sx={styles.row}>
            <Typography style={styles.subTitle}>{t('mail_objective') + ' : '}</Typography>
            <Box sx={{ width: '10px' }} />
            <Tooltip
              sx={{ cursor: description && description.length > 60 && 'pointer' }}
              title={description && description.length > 60 ? description : null}>
              <Typography style={styles.value}>{truncateString(`${description}`, 60)}</Typography>
            </Tooltip>
          </Box>
        )}
        <Box sx={styles.row}>
          <Typography style={styles.subTitle}>{t(EMAIL_SUBJECT) + ' : '}</Typography>
          <Box sx={{ width: '10px' }} />
          <Tooltip
            sx={{ cursor: subjectTranslated > 60 && 'pointer' }}
            title={subjectTranslated.length > 60 ? subjectTranslated : null}>
            <Typography style={styles.value}>{truncateString(`${subjectTranslated}`, 60)} </Typography>
          </Tooltip>
        </Box>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        {/** Content of dialog */}

        <Box sx={styles.contentEmail}>
          <div
            className="email-template-scope"
            dangerouslySetInnerHTML={{ __html: getEmailTemplate(contentTranslated, footer, customHeaderPicture) }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={styles.containerButtons}>
        {/* Instead of these buttons actions, make them  enable or disable the email switch */}
        {/* <Button variant="outlined" onClick={handleClose} size="large" sx={styles.btnBack}>
          {'BACK'}
        </Button>
        <Button variant="contained" sx={styles.btnSave} onClick={handleClose} size="large">
          {t(VALIDATE)}
        </Button> */}
      </DialogActions>
    </Dialog>
  );
};

export default PreviewEmail;
