import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Box, Typography } from '@mui/material';
import FormControlLabel from '@mui/material/FormControlLabel';
import { MenuItem, Switch, Select, IconButton, Tooltip } from '@mui/material';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import useRemindersSettings from 'hooks/usecase/useRemindersSettings';
import PreviewEmail from '../../../subscription/create/Modals/';
import { useListEmailsWithName, useMeQuery } from 'hooks/queries';

import styles from './RemindersSettings.styles';
import { t } from 'i18next';

const mappingReceiverWithUserCompany = {
  mentee: 'clientLabel',
  mentor: 'expertLabel',
};

const RemindersSettings = () => {
  const { t } = useTranslation();
  const {
    actions: { updateRemindersSettings, onSwitchEnableAll, onSwitchFirstColumnReminder, onSwitchSecondColumnReminder },
    getters: { getRemindersWithCategories },
    state: { categories, switchEnableAll, switchFirstColumnReminder, switchAllSecondReminder },
    isLoading,
    isRefetching,
  } = useRemindersSettings();

  const { data: user } = useMeQuery();

  const [templateMailRecipientType, setTemplateMailRecipientType] = useState('');
  const [templateMailSelected, setTemplateMailSelected] = useState('');
  const { data: dataEmailTemplate } = useListEmailsWithName(templateMailSelected);

  if (isLoading || isRefetching) {
    return <p>Loading...</p>;
  }

  return (
    <>
      <Typography fontWeight={600} fontSize={'20px'}>
        {t('reminder_title')}
      </Typography>

      <Typography sx={styles.description} fontWeight={400} fontSize={'16px'}>
        {t('reminder_subtitle')}
      </Typography>

      <Box sx={styles.content}>
        <RemindersSettingsHeader
          switchEnableAll={switchEnableAll}
          switchFirstColumnReminder={switchFirstColumnReminder}
          switchAllSecondReminder={switchAllSecondReminder}
          onSwitchEnableAll={onSwitchEnableAll}
          onSwitchFirstColumnReminder={onSwitchFirstColumnReminder}
          onSwitchSecondColumnReminder={onSwitchSecondColumnReminder}
        />

        {categories.map((category) => {
          return (
            <RemindersSettingsSections
              user={user}
              key={category}
              category={category}
              config={getRemindersWithCategories(category)}
              onChangeSetting={(config) => updateRemindersSettings([config])}
              onTemplateMailSelected={(reminderConfig, index) => {
                const templateEmail = Array.isArray(reminderConfig.templateEmail)
                  ? reminderConfig.templateEmail[index - 1]
                  : reminderConfig.templateEmail;
                setTemplateMailRecipientType(mappingReceiverWithUserCompany[reminderConfig.receiver]);
                setTemplateMailSelected(templateEmail);
              }}
            />
          );
        })}
      </Box>

      <PreviewEmail
        title={user.company[templateMailRecipientType]}
        clientEmail={''}
        isOpen={!!templateMailSelected}
        setOpen={() => setTemplateMailSelected('')}
        content={dataEmailTemplate?.content}
        templateName={dataEmailTemplate?.name}
        fromName={dataEmailTemplate ? dataEmailTemplate.fromName : ''}
        subject={dataEmailTemplate?.subject}
        customHeaderPicture={user?.company?.customHeaderPicture}
      />
    </>
  );
};

const RemindersSettingsSections = ({ category, config, user, onTemplateMailSelected, onChangeSetting }) => {
  const multipleRoles = category.length > 1;

  const firstColumnStyle = multipleRoles ? { ...styles.column1, height: '75px' } : styles.column1;

  const onChange = (reminderConfig, index, delay, activated) => {
    if (delay && activated !== null && activated !== undefined) {
      onChangeSetting({ ...reminderConfig, delay, activated, index });
    } else if (delay) {
      onChangeSetting({ ...reminderConfig, delay, index });
    } else if (activated !== undefined) {
      onChangeSetting({ ...reminderConfig, activated, index });
    }
  };

  const findReminderCompany = (reminderConfig, index) => {
    const remindersCompany = reminderConfig.remindersCompany || [];
    const configFound = remindersCompany.find((config) => config.index === index) || {};

    const { autoFillingSecondNotificationWithMultiple } = reminderConfig;

    // Set the value according to the first input
    if (autoFillingSecondNotificationWithMultiple && index === 2) {
      const firstConfigFound = remindersCompany.find((config) => config.index === 1) || {};
      const firstDelay = firstConfigFound.delay || reminderConfig.delays[0];
      return { ...configFound, delay: firstDelay * autoFillingSecondNotificationWithMultiple };
    }

    return configFound;
  };

  // If the value is potentially a multiple of the first value, this value must be created as soon as possible.
  const secondColumnDelays = (reminderConfig) => {
    if (reminderConfig.autoFillingSecondNotificationWithMultiple) {
      const { delay } = findReminderCompany(reminderConfig, 2);
      return [delay];
    }

    if (reminderConfig.trigger === 'before') {
      return reminderConfig.delays.slice(0, -1);
    }

    return reminderConfig.delays.slice(1);
  };

  const firstColumnDelays = (reminderConfig) => {
    if (reminderConfig.possibleNumberOfNotifications === 1) {
      return reminderConfig.delays;
    }

    if (reminderConfig.trigger === 'before') {
      return reminderConfig.delays.slice(1);
    }

    return reminderConfig.delays.slice(0, -1);
  };

  const { expertLabel, clientLabel } = user.company;

  return (
    <>
      <Box sx={styles.section}>
        <Box sx={firstColumnStyle}>
          <Typography fontWeight={600} fontSize={'16px'}>
            {t(`reminder_settings.categories.${category}.title`, { expertLabel, clientLabel })}
          </Typography>
          <Typography color="#475467" fontSize={'14px'}>
            {t(`reminder_settings.categories.${category}.desc`, { expertLabel, clientLabel })}
          </Typography>
        </Box>
        <Box sx={styles.column2}>
          {config.map(({ trigger, receiver }) => {
            return (
              <Typography key={`${category}-${trigger}-${receiver}-role`} fontSize={'14px'}>
                {user.company[mappingReceiverWithUserCompany[receiver]]}
              </Typography>
            );
          })}
        </Box>
        <Box sx={styles.column3}>
          {config.map((reminderConfig) => {
            return (
              <DelaySelector
                key={`${category}-${reminderConfig.trigger}-${reminderConfig.receiver}-selector-first-notification`}
                reminderConfig={reminderConfig}
                trigger={reminderConfig.trigger}
                delays={firstColumnDelays(reminderConfig)}
                onTemplateMailSelected={(config) => onTemplateMailSelected(config, 1)}
                isActivated={findReminderCompany(reminderConfig, 1).activated}
                delaySelected={findReminderCompany(reminderConfig, 1).delay}
                index={1}
                onChange={({ delay, activated }) => onChange(reminderConfig, 1, delay, activated)}
              />
            );
          })}
        </Box>
        <Box sx={styles.column4}>
          {config.map((reminderConfig) => {
            return reminderConfig.possibleNumberOfNotifications > 1 ? (
              <DelaySelector
                key={`${category}-${reminderConfig.trigger}-${reminderConfig.receiver}-selector-first-notification`}
                reminderConfig={reminderConfig}
                trigger={reminderConfig.trigger}
                delays={secondColumnDelays(reminderConfig)}
                selectorDisabled={!!reminderConfig.autoFillingSecondNotificationWithMultiple}
                onTemplateMailSelected={(config) => onTemplateMailSelected(config, 2)}
                isActivated={findReminderCompany(reminderConfig, 2).activated}
                delaySelected={findReminderCompany(reminderConfig, 2).delay}
                index={2}
                onChange={({ delay, activated }) => onChange(reminderConfig, 2, delay, activated)}
              />
            ) : (
              <Box sx={{ height: '40px' }} />
            );
          })}
        </Box>
      </Box>
    </>
  );
};

const DelaySelector = ({
  delays,
  trigger,
  isActivated,
  delaySelected,
  reminderConfig,
  selectorDisabled,
  onChange,
  onTemplateMailSelected,
  index,
}) => {
  const { t } = useTranslation();
  const [selectDelay, setSelectDelay] = useState(delaySelected === undefined ? delays[0] : delaySelected);
  const [activated, setActivated] = useState(isActivated || reminderConfig.required || false);

  const menuItemDelay = (delay, trigger) => {
    const isDay = delay % (24 * 60) === 0;
    const isHours = delay % 60 === 0;

    const count = isDay ? delay / (24 * 60) : isHours ? delay / 60 : delay;
    const typeDelay = isDay ? 'day' : isHours ? 'hour' : 'min';
    const isInfinity = reminderConfig.infinity ? '_infinity' : '';

    return (
      <MenuItem value={delay}>{t(`menu_item_${trigger.toLowerCase()}_${typeDelay}${isInfinity}`, { count })}</MenuItem>
    );
  };

  const switchTooltip = (config) => {
    if (config.required) {
      return t('reminder_required_tooltip');
    }

    return '';
  };

  const selectTooltip = (config) => {
    if (config.autoFillingSecondNotificationWithMultiple && index === 2) {
      return t('reminder_auto_filling_tooltip');
    }

    return '';
  };

  return (
    <Box sx={styles.delaySelectorContainer}>
      <FormControlLabel
        sx={{ marginRight: '0' }}
        control={
          <Tooltip title={switchTooltip(reminderConfig)}>
            <div>
              <Switch
                checked={activated}
                disabled={reminderConfig.required}
                onChange={(e) => {
                  setActivated(e.target.checked);
                  onChange({ activated: e.target.checked, delay: selectDelay });
                }}
              />
            </div>
          </Tooltip>
        }
      />

      <Tooltip title={selectTooltip(reminderConfig)}>
        <Select
          sx={styles.delaySelector}
          disabled={selectorDisabled}
          value={selectDelay}
          onChange={(e) => {
            setSelectDelay(e.target.value);
            onChange({ delay: e.target.value, activated });
          }}>
          {delays.map((delay) => menuItemDelay(delay, trigger))}
        </Select>
      </Tooltip>

      <IconButton sx={styles.showEmailTemplate} size="small" onClick={() => onTemplateMailSelected(reminderConfig)}>
        <VisibilityOutlinedIcon fontSize="small" />
      </IconButton>
    </Box>
  );
};

const RemindersSettingsHeader = ({
  switchEnableAll,
  switchFirstColumnReminder,
  switchAllSecondReminder,
  onSwitchEnableAll,
  onSwitchFirstColumnReminder,
  onSwitchSecondColumnReminder,
}) => {
  return (
    <>
      <Box sx={styles.header}>
        <Box sx={styles.column1}>
          <FormControlLabel
            control={<Switch checked={switchEnableAll} onChange={(e) => onSwitchEnableAll(e.target.checked)} />}
            label={t('reminder_enable_all')}
          />
        </Box>
        <Box sx={styles.column2}>
          <Typography fontSize={'14px'}>{t('reminder_column_role')}</Typography>
        </Box>
        <Box sx={styles.column3}>
          <FormControlLabel
            control={
              <Switch
                checked={switchFirstColumnReminder}
                onChange={(e) => onSwitchFirstColumnReminder(e.target.checked)}
              />
            }
            label={t('reminder_enable_first_notification')}
          />
        </Box>
        <Box sx={styles.column4}>
          <FormControlLabel
            control={
              <Switch
                checked={switchAllSecondReminder}
                onChange={(e) => onSwitchSecondColumnReminder(e.target.checked)}
              />
            }
            label={t('reminder_enable_second_notification')}
          />
        </Box>
      </Box>
    </>
  );
};

export default RemindersSettings;
