/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

// Lib dependencies
import React from 'react';

// UI lib dependencies;
import Typography from '@mui/material/Typography';

// Style
import styles from './styles';
import { parseJsonTranslate } from 'utils/parse';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

function CellSmallText({ text }) {
  /* ******************************** RENDERING ******************************* */
  return <Typography sx={styles.cellText}>{parseJsonTranslate(text)}</Typography>;
}

export default CellSmallText;
