import React, { useEffect, useState, Suspense } from 'react';
import { useMeQuery } from 'hooks/queries';
import { useTranslation } from 'react-i18next';
import { useListEmailsCompanyWithParams } from 'hooks/queries';
import { Grid, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import DataGrid from 'ui/components/DataGrid';
import PreviewEmail from 'ui/pages/subscription/create/Modals/PreviewEmail';
import { parseJsonTranslate } from 'utils/parse';

import { tableSchemaBase as columns } from './EmailTemplate.config';

import styles from './EmailTemplate.styles';

const EmailTemplate = () => {
  const { t } = useTranslation();
  const [categorySelected, setCategorySelected] = useState('');
  const { data: user } = useMeQuery();
  const { data, isLoading } = useListEmailsCompanyWithParams(user.company.id, {
    showAllTemplate: true,
    page: 1,
    size: 100,
  });
  const [currentSize, setCurrentSize] = useState(25);
  const [currentPage, setCurrentPage] = useState(1);
  const [templateMailSelected, setTemplateMailSelected] = useState(false);
  const [dataEmailTemplateSelected, setDataEmailTemplateSelected] = useState({});

  const emailTemplates = data?.rows || [];

  const categories = [...new Set(emailTemplates.map((template) => template.category))];
  const emailTemplatesFiltered = emailTemplates.filter(({ category }) => category === categorySelected);

  const { clientLabel, expertLabel, customHeaderPicture } = user.company;

  useEffect(() => {
    if (categories?.length && !categorySelected) {
      setCategorySelected(categories[0]);
    }
  }, [categories]);

  useEffect(() => {
    setCurrentPage(1);
  }, [categorySelected]);

  if (isLoading) return <p>Loading...</p>;

  const handleOnFilter = (filters, currentPage, currentSize) => {
    console.log('handleOnFilter', filters, currentPage, currentSize);
  };

  const handleOnSort = (sort, currentPage, currentSize) => {
    console.log('handleOnSort', sort, currentPage, currentSize);
  };

  const handleOnPagination = (page, nbrPages) => {
    setCurrentPage(page);
    setCurrentSize(nbrPages);
  };

  const updateList = (list) => {
    return list.slice((currentPage - 1) * currentSize, currentPage * currentSize).map((line) => {
      const languages = line?.content && typeof line.content === 'object' ? Object.keys(line.content) : [];

      return {
        ...line,
        category: t(`email_template.category.${line.category}`),
        scope: t(`email_template.scope.${line.scope}`),
        languages: languages.length !== 0 ? languages.map((lng) => t(`lang_${lng}`)).join(', ') : '-',
        desc: t(`email_template.template_desc.${line.name}`, { expertLabel, clientLabel }),
        subject: parseJsonTranslate(line.subject),
        targetRoles: line.targetRoles
          ? line.targetRoles
              .map((role) => t(`email_template.target_role.${role}`, { clientLabel, expertLabel }))
              .join(' / ')
          : '-',
      };
    });
  };

  return (
    <>
      <Typography sx={styles.title}>{t('email_template.title')}</Typography>

      <Typography sx={styles.description}>{t('email_template.desc')}</Typography>

      <Grid container spacing={4} sx={{ marginTop: '12px' }}>
        <Grid item xs={12} md={3}>
          <Typography
            variant="h4"
            sx={{
              fontFamily: 'Karla',
              fontSize: '15px',
              fontStyle: 'normal',
              fontWeight: 600,
              lineHeight: '150%', // 24px
              letterSpacing: '0.15px',
            }}>
            {t('email_template.list-title')}
          </Typography>
          <List sx={{ width: '100%', minWidth: '100%' }}>
            {categories.map((category) => (
              <ListItemButton
                selected={category === categorySelected}
                key={category}
                disableGutters
                onClick={() => {
                  setCategorySelected(category);
                }}
                sx={{ padding: '15px', borderRadius: '5px', borderBottom: '1px solid rgb(237, 237, 237)' }}>
                <ListItemText primary={t(`email_template.category.${category}`)} />
              </ListItemButton>
            ))}
          </List>
        </Grid>
        <Grid item xs={12} md={9}>
          <Suspense fallback={<div>Loading...</div>}>
            <DataGrid
              columns={columns(t)}
              rows={updateList(emailTemplatesFiltered)}
              onPaginationChange={handleOnPagination}
              loading={isLoading}
              totalItems={emailTemplatesFiltered.length}
              totalPages={Math.ceil(emailTemplatesFiltered.length / currentSize)}
              onSort={handleOnSort}
              onFilter={handleOnFilter}
              onRowClicked={(emailTemplate, event) => {
                if (!event.defaultMuiPrevented) {
                  setTemplateMailSelected(true);
                  setDataEmailTemplateSelected(emailTemplate?.row || {});
                  return;
                }
              }}
            />

            <PreviewEmail
              title={''}
              clientEmail={''}
              isOpen={!!templateMailSelected}
              setOpen={() => setTemplateMailSelected('')}
              templateName={dataEmailTemplateSelected?.name}
              content={dataEmailTemplateSelected?.content}
              fromName={dataEmailTemplateSelected ? dataEmailTemplateSelected.fromName : ''}
              subject={dataEmailTemplateSelected?.subject}
              customHeaderPicture={customHeaderPicture}
              targetRoles={dataEmailTemplateSelected?.targetRoles}
            />
          </Suspense>
        </Grid>
      </Grid>
    </>
  );
};

export default EmailTemplate;
