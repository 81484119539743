const tableSchemaBase = (t) => {
  return [
    {
      field: 'targetRoles',
      headerName: t('email_template.table.target_roles'),
      type: 'smallText',
      hideable: false,
      minWidth: 130,
      resizable: true,
      flex: 0.5,
      sortable: false,
      filterable: false,
    },
    {
      field: 'desc',
      headerName: t('email_template.table.description'),
      type: 'smallTextWithTooltip',
      hideable: false,
      minWidth: 130,
      resizable: true,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'subject',
      headerName: t('email_template.table.subject'),
      type: 'smallTextWithTooltip',
      hideable: false,
      minWidth: 130,
      resizable: true,
      flex: 1,
      sortable: false,
      filterable: false,
    },
    {
      field: 'languages',
      headerName: t('email_template.table.available_languages'),
      type: 'smallText',
      hideable: false,
      minWidth: 130,
      resizable: true,
      flex: 0.75,
      sortable: false,
      filterable: false,
      headerAlign: 'center',
      align: 'center',
    },
  ];
};

export { tableSchemaBase };
