/* -------------------------------------------------------------------------- */
/*                                Dependencies                                */
/* -------------------------------------------------------------------------- */

import React, { useState, useRef, useEffect } from 'react';

// UI Lib dependencies
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Tooltip from '@mui/material/Tooltip';
import Switch from 'ui/components/form/Switch';
import Select from 'ui/components/form/Select';
import MultiSelect from 'ui/components/shared/MultiSelect';
import TabLangues from 'ui/components/TabLangues/TabLangues';

// UI Local components
import Input from 'ui/components/Input';
import TitleInput from 'ui/components/TitleInput';
import Editor from 'ui/components/Editor';
import { t } from 'i18next';
import { useForm } from 'react-hook-form';
import { getContentTranslate } from 'utils/parse';

// Icons
import CloseIcon from '@mui/icons-material/Close';

// Styles
import styles from './CreateEmailTemplate.styles';

// Configs
import { TITLE } from 'litterals';
import { truncateString } from 'common/utils';

// Templates
import { getEmailTemplate } from 'utils/templateEmailUtils';
import { useEmailStructure } from 'hooks/queries';
import { parseJsonTranslate } from 'utils/parse';

/* -------------------------------------------------------------------------- */
/*                                  Component                                 */
/* -------------------------------------------------------------------------- */

const CreateTemplateEmail = ({
  actions,
  optionsTab,
  setOptionsTab,
  control,
  isOpen,
  setOpen,
  onAdd,
  reset,
  watch,
  user,
  setSelectedTab,
  expertLabel,
  clientLabel,
  customHeaderPicture,
}) => {
  const refTargetRoles = useRef();
  const [languagesConfigured, setLanguagesConfigured] = useState([]);
  const [languageSelected, setLanguageSelect] = useState('');
  const { data: templateEmailData, isLoading } = useEmailStructure();
  /* ********************************  HELPERS ******************************* */
  const handleClose = () => {
    setOpen(false);
    /*
    setSelectedTab('tab-1');
    setOptionsTab([
      { id: 'tab-1', text: 'Rich Text', selected: true },
      { id: 'tab-2', text: 'HTML Editor', selected: false },
    ]);
    */
    reset();
  };

  useEffect(() => {
    const content = watch('content');

    if (content && typeof content === 'object' && content !== null) {
      const languages = Object.keys(content);
      setLanguagesConfigured(languages);

      if (languages.length && !languageSelected) setLanguage(languages[0]);
    } else {
      setLanguagesConfigured([]);
      setLanguage('', false);
    }
  }, [watch('id')]);

  const {
    control: internalControl,
    setValue: internalSetValue,
    watch: internalWatch,
  } = useForm({ mode: 'all', defaultValues: { subject: '' } });

  const visibilityLabel = watch('visible') ? t('email_template.visible.true') : t('email_template.visible.false');

  const scopeOptions = Object.keys(t('email_template.scope')).map((value) => {
    return { label: t(`email_template.scope.${value}`), value };
  });

  const categoryOptions = Object.keys(t('email_template.category')).map((value) => {
    return { label: t(`email_template.category.${value}`), value };
  });

  const targetRolesOptions = Object.keys(t('email_template.target_role')).map((value) => {
    return { label: t(`email_template.target_role.${value}`, { clientLabel, expertLabel }), value };
  });

  const onAddLangueClick = (lng) => {
    setLanguagesConfigured([...languagesConfigured, lng]);
    actions.addLanguage(lng);
    setLanguage(lng, true);
  };

  const onLangueClick = (lng) => {
    setLanguage(lng);
  };

  const onDeleteLangue = () => {
    setLanguagesConfigured([...languagesConfigured.filter((line) => line !== languageSelected)]);

    actions.deleteLanguage(languageSelected);

    if (languagesConfigured.length) {
      setLanguage(languagesConfigured[0], false);
    } else {
      setLanguage(null, false);
    }
  };

  const saveInControl = () => {
    actions.setValueLanguage(languageSelected, 'content', internalWatch('content'));
    actions.setValueLanguage(languageSelected, 'subject', internalWatch('subject'));
  };

  const setLanguage = (language, send = true) => {
    if (languageSelected && send) {
      saveInControl();
    }

    setLanguageSelect(language);

    const currentSubject = language ? getContentTranslate(watch('subject'), language) : '';
    const currentContent = language ? getContentTranslate(watch('content'), language) : '';

    internalSetValue('subject', currentSubject, { shouldDirty: true, shouldValidate: true });
    internalSetValue('content', currentContent, { shouldDirty: true, shouldValidate: true });
  };

  const handleAdd = () => {
    saveInControl();
    onAdd();
  };

  if (isLoading) return <p>Loading...</p>;

  const footer = parseJsonTranslate(templateEmailData.footer, false, languageSelected);

  const emailPreview = getEmailTemplate(internalWatch('content'), footer, customHeaderPicture);

  return (
    <Dialog
      disableEnforceFocus
      PaperProps={{ sx: styles.containerSessionTemplateDialog }}
      open={isOpen}
      onClose={handleClose}>
      <DialogTitle sx={styles.containerTitle}>
        <Tooltip title={watch('name')}>
          <Typography style={styles.title}>
            {'Create Email Template ' + truncateString(`${watch('name')}`, 30)}
          </Typography>
        </Tooltip>
        <Box sx={styles.header}>
          <TitleInput
            disabled={false}
            // eslint-disable-next-line no-unused-vars
            exportTitle={(title) => {
              //handleChangeSession(selectedSession.id, { name: title });
            }}
            control={control}
            actions={() => {}}
            session={null}
            placeholder={t(TITLE)}
            name="name"
            sx={styles.titleContent}
            inputProps={{ style: styles.titleContent }}
          />

          <Switch
            sx={{ color: 'primary.grey' }}
            control={control}
            variant="outlined"
            label={visibilityLabel}
            labelPlacement="end"
            name="visible"
          />
        </Box>
      </DialogTitle>
      <DialogContent sx={styles.content}>
        <IconButton aria-label="close" onClick={handleClose} sx={styles.closeButton}>
          <CloseIcon />
        </IconButton>
        <Stack sx={{ minHeight: '630px', backgroundColor: 'white', paddingTop: '50px' }}>
          <Box>
            <TabLangues
              languageSelected={languageSelected}
              languagesEnabled={languagesConfigured}
              onAddLangueClick={onAddLangueClick}
              onDeleteLangue={onDeleteLangue}
              onLangueClick={onLangueClick}
            />
          </Box>

          <Box sx={{ display: 'flex', flexDirection: 'row' }}>
            <Box sx={styles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={internalControl}
                disabled={!languageSelected}
                variant="outlined"
                label="Email subject"
                name="subject"
              />
            </Box>
            <Box sx={{ width: '48px' }} />
            <Box sx={styles.containerInputsSessionDialog}>
              <Input
                ref={null}
                rules={{ required: 'This field is required' }}
                control={control}
                variant="outlined"
                label="FromName"
                name="fromName"
              />
            </Box>
          </Box>

          <Box sx={{ display: 'flex', gap: '24px', flexDirection: 'column' }}>
            <Select
              name="scope"
              label={t('email_template.scope_label')}
              control={control}
              options={scopeOptions}
              defaultValue=""
            />
            <Select
              name="category"
              label={t('email_template.category_label')}
              control={control}
              options={categoryOptions}
              defaultValue=""
            />
            <MultiSelect
              label={t('email_template.target_roles_label')}
              ref={refTargetRoles}
              name="targetRoles"
              variant="outlined"
              control={control}
              options={targetRolesOptions}
            />
          </Box>

          {languageSelected && (
            <Box sx={styles.containerInputsSessionDialog}>
              <Editor
                role={user && user.role ? user.role : 'ADMIN'}
                title="Content"
                control={internalControl}
                name="content"
                label={'Content'}
                actions={() => {}}
              />
            </Box>
          )}
          {languageSelected && (
            <Box
              sx={{
                border: '1px solid #F9F9F9',
                display: 'flex',
                flex: 1,
                flexDirection: 'column',
                padding: '0px 100px',
              }}>
              <Typography
                sx={{
                  fontWeight: 700,
                  fontSize: '24px',
                  color: 'title.main',
                  alignSelf: 'center',
                }}>
                {'Email Template Preview'}
              </Typography>
              <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center' }}>
                <div className="email-template-scope" dangerouslySetInnerHTML={{ __html: emailPreview }} />
              </Box>
            </Box>
          )}
        </Stack>
        {/** Content of dialog */}
      </DialogContent>
      <DialogActions sx={styles.btnBack}>
        <Button variant="outlined" onClick={handleClose} size="large">
          {'BACK'}
        </Button>
        <Button
          variant="contained"
          sx={styles.btnSave}
          onClick={() => {
            handleAdd();
          }}
          size="large">
          {'SAVE'}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateTemplateEmail;
